import { createGetKcContext, PageId } from 'keycloakify/login';

export type KcContextExtension = {
  pageId: PageId;
  skipLink?: boolean;
};

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: 'login.ftl',
      locale: {
        currentLanguageTag: 'ru',
      },
      social: {
        providers: [
          {
            displayName: 'testfederation',
            providerId: 'saml',
            loginUrl:
              '/oauth/realms/rtk/broker/haRuP2Oozkdpu5Ey/login?cl…_code=GJfUzodj7hUM7DcoCq3lKnz83GSG-P_x-8v96sF9rOg',
            alias: 'haRuP2Oozkdpu5Ey',
          },
        ],
      },
      //Uncomment the following line for hiding the Alert message
      //"message": undefined
      //Uncomment the following line for showing an Error message
      //message: { type: "error", summary: "This is an error" }
    },
    {
      pageId: 'login-reset-password.ftl',
      locale: {
        currentLanguageTag: 'ru',
      },
    },
    {
      pageId: 'login-update-password.ftl',
      locale: {
        currentLanguageTag: 'ru',
      },
    },
    {
      pageId: 'login-otp.ftl',
      locale: {
        currentLanguageTag: 'ru',
      },
    },
    {
      //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
      pageId: 'register-user-profile.ftl',
      locale: {
        currentLanguageTag: 'fr',
      },
      profile: {
        attributes: [
          {
            validators: {
              pattern: {
                pattern: '^[a-zA-Z0-9]+$',
                'ignore.empty.value': true,
                // eslint-disable-next-line no-template-curly-in-string
                'error-message': '${alphanumericalCharsOnly}',
              },
            },
            //NOTE: To override the default mock value
            value: undefined,
            name: 'username',
          },
          {
            validators: {
              options: {
                options: [
                  'male',
                  'female',
                  'non-binary',
                  'transgender',
                  'intersex',
                  'non_communicated',
                ],
              },
            },
            // eslint-disable-next-line no-template-curly-in-string
            displayName: '${gender}',
            annotations: {},
            required: true,
            groupAnnotations: {},
            readOnly: false,
            name: 'gender',
          },
        ],
      },
    },
    {
      pageId: 'register.ftl',
      locale: {
        currentLanguageTag: 'ru',
      },
      // Simulate we got an error with the email field
      messagesPerField: {
        printIfExists: <T>(fieldName: string, className: T) => {
          console.log({ fieldName });
          return fieldName === 'email' ? className : undefined;
        },
        existsError: (fieldName: string) => fieldName === 'email',
        get: (fieldName: string) => `Fake error for ${fieldName}`,
        exists: (fieldName: string) => fieldName === 'email',
      },
    },
    {
      pageId: 'terms.ftl',
      locale: {
        currentLanguageTag: 'ru',
      },
    },
    {
      pageId: 'error.ftl',
      locale: {
        currentLanguageTag: 'ru',
      },
    },
  ],
});

export const { kcContext } = getKcContext({
  // Uncomment to test the login page for development.
  //mockPageId: "login.ftl",
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']> &
  KcContextExtension;
